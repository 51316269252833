import { useContentful, withContentful } from 'react-contentful';
import ConsultationForm from "./ConsultationForm";
import YoutubeEmbed from './YoutubeEmbed';
import StepsToTrans from './StepsToTrans';
import FancySuccessStories from './FancySuccessStories';
import ProgramBenefits from './ProgramBenefits';
import FAQs from './FAQs';
import Button from './Button';
import ReactMarkdown from 'react-markdown';

const TansformationSection = ({ entry, index }) => {
  const { title, description, hideTitle, image, youtubeId, bgImage, showTransformationButton } = entry.fields;
  const sectionStyles = bgImage ? { backgroundImage: `url(${bgImage.fields.file.url})` } : {};
  const contentBg = (index % 2) ? 'bg-orange-100' : 'bg-blue-100';

  return (
    <section className="bg-cover bg-center w-full" style={sectionStyles}>
      <div className="mx-auto max-w-screen-2xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 text-left">
          { image ? (
            <div className="flex relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last aspect-w-1 aspect-h-1 self-center lg:-ml-12">
              <img
                src={image.fields.file.url}
                className="absolute inset-0 h-full w-full object-cover rounded-xl shadow-lg"
              />
            </div>
          ) : null }
          {youtubeId ? (
            <div className="flex relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last aspect-w-16 aspect-h-9 self-center lg:-ml-12">
              <YoutubeEmbed embedId={entry.fields.youtubeId} />
            </div>
          ) : null}
          <div className={ `p-12 lg:p-24 lg:pr-32 max-h-lg lg:-mr-12 ${contentBg} rounded-lg shadow-2xl` }>
            { !hideTitle && <h2 className="text-gray-800 text-3xl sm:text-5xl">{title}</h2> }
            <div className="my-4 text-gray-800 space-y-4">
              <ReactMarkdown className="markdown">{description}</ReactMarkdown>
            </div>
            { showTransformationButton && <Button onClick={() => window.scrollTo(0, 0)} label="Schedule My Consultation!" size="lg" /> }
          </div>
        </div>
      </div>
    </section>
  );
}

const StartTransformation = ({ contentful }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'consultationSectionOrder',
    include: 10
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No Transformation Sections :(</p>;
  }
  const entries = client.parseEntries(data).items[0].fields.consultationSection;

  return (
    <>
      <div id="hero" className="px-8 md:px-16 lg:px-32 pt-24 pb-16 md:pb-32 md:pt-32 hero -mt-16">
        <div className="flex flex-col md:flex-row gap-8 justify-center">
          <div className="w-full max-w-4xl rounded-xl overflow-hidden">
            <YoutubeEmbed embedId="eXiJkXGdttY" />
          </div>
          <div className="flex-shrink-0">
            <ConsultationForm />
          </div>
        </div>
      </div>
      <div className="flex flex-col hidden">
        <h2 className="text-6xl text-white mb-8">Transformations That Inspire</h2>
        <FancySuccessStories />
      </div>
      <div className="flex flex-col mb-16 lg:px-32">
        <div className="flex flex-col gap-16 self-center w-full">
          { entries.map((entry, i) => {
              if (entry.fields.contentType === 'steps') {
                return <StepsToTrans />;
              } else if (entry.fields.contentType === 'benefits') {
                return <ProgramBenefits />;
              } else {
                return <TansformationSection entry={entry} key={entry.sys.id} index={i} />;
              }
            }
          )}
        </div>
      </div>
      <FAQs />
    </>
  );
};

export default withContentful(StartTransformation);