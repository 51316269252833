const Step = ({ number = '', title = '', description = '' }) => {
  return (
    <div className="flex flex-col gap-4 text-left">
      <div className="flex-shrink">
        <h4 className="text-cb text-4xl w-16 h-16 rounded-full bg-white leading-[4rem] text-center">{number}</h4>
      </div>
      <h4 className="text-white text-4xl">{title}</h4>
      <p className="text-white text-sm leading-relaxed">{description}</p>
    </div>
  );
}


const StepsToTrans = () => {
  return (
    <div className="flex flex-col px-8 py-12 md:py-16 md:px-16 lg:px-32">
      <h3 className="text-white text-6xl mb-16">Steps to <span className="text-lb">transformation</span></h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <Step number="1" title="Get Honest" description="Get honest and motivated. To reach your goals, we’ll work on changing your thinking and mindset." />
        <Step number="2" title="Unlock your potential" description="Embark on Charles' coaching program to unlock your potential for success. Develop good habits and move closer to your goals." />
        <Step number="3" title="Grow" description="Receive professional coaching and accountability from Charles. Regular meetings and support will ensure your growth and development." />
        <Step number="4" title="Flourish" description="The program brings more than just weight loss, but a complete life transformation. Experience increased energy, renewed hope, and improved relationships. Use your newfound success strategies to tackle new challenges and embrace opportunities." />
      </div>
    </div>
  );
}

export default StepsToTrans;