import { useState, useEffect } from 'react';
import { useMailChimp, Status } from './hooks/useMailchimp';
import ReactGA from "react-ga4";
import Button from './Button';
import EmailValidator from 'email-validator';

const ConsultationForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = ev => {
    ev.preventDefault();

    const newErrors = [];

    if (!name) {
      newErrors.push('Please enter your name')
    }

    if (!email || !EmailValidator.validate(email)) {
      newErrors.push('Please entire a valid email');
    }

    if (!phone || phone.length < 8) {
      newErrors.push('Please enter a valid phone number');
    }

    setErrors(newErrors);

    if (newErrors.length) {
      return;
    }

    setSubmitting(true);
    const formData = new FormData(ev.target);
    fetch("https://getform.io/f/b841effe-db89-4a95-9caa-972a37f83ebc", {
        method: "POST",
        body: formData,
        headers: {
            "Accept": "application/json",
        },
    })
    .then(res => {
      console.warn(ReactGA);
      ReactGA.event({
        category: 'consultation',
        action: "submit_form"
      });
      setSubmitting(false);
      setSuccess(true);
    })
    .catch(error => {
      console.log(error);
      setSubmitting(false);
    });
  };

  if (success) {
    return (
      <div className="flex flex-col">
        <h3 className="text-4xl uppercase text-orange-400 mb-2">Congratulations!</h3>
        <p className="text-white text-lg max-w-md">Thank you for submitting the form! Charles will be in touch shortly to guide you on the path to a healthier, happier you. Get ready to transform your life!</p>
      </div>
    );
  }

  return (
    <div className="flex">
      <div className="flex w-full md:max-w-4xl flex-col self-center">
        <>
          <h3 className="text-5xl uppercase text-white mb-2 text-center">Get your <span className="text-orange-400">free</span><br />consultation</h3>
          <form className="flex flex-col space-y-2 mb-2" onSubmit={onSubmit}>
            <input className="h-12 rounded px-4" type="text" placeholder="Name" name="name" onChange={ev => setName(ev.target.value)} />
            <input className="h-12 rounded px-4" type="text" placeholder="Email" name="email" onChange={ev => setEmail(ev.target.value)} />
            <input className="h-12 rounded px-4" type="text" placeholder="Phone" name="phone" onChange={ev => setPhone(ev.target.value)} />
            <input className="hidden" type="hidden" name="_gotcha" />
            {(errors && errors.length) ?
              <div className="bg-red-600 rounded text-white text-sm py-2 md:max-w-sm">
                {errors.map((error, i) => (
                  <p className="py-1" key={i}>{error}</p>
                ))}
              </div> : null
            }
            <Button type="submit" label="Contact Charles Now" size="lg" loading={submitting} loadingLabel="Submitting..." />
          </form>
          <span className="text-white font-semibold text-center">Or call Charles's office at <a href="tel:3144953228" className="text-orange-500">314.495.3228</a></span>
          <span className="text-white text-sm text-center">Virtual consultations available at a fee</span>
        </>
      </div>
    </div>
  );
}

export default ConsultationForm;