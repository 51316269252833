import { useState } from 'react';
import { useContentful, withContentful } from 'react-contentful';
import Button from './Button';
import { NavArrowLeft, NavArrowRight } from 'iconoir-react';
import ReactMarkdown from 'react-markdown';

const Endorsement = ({ name = "", title = "", quote = "", image = "", index = 0 }) => {

  return (
    <div className={ `flex flex-col md:flex-row gap-8 justify-items-start flex-row` }>
      <div className="flex-shrink-0">
        <img className="rounded-xl shadow-2xl"width="280" height="280" src={image.url + '?fit=thumb&w=360&h=360&f=center'} />
      </div>
      <div className={`flex flex-col text-left`}>
        <h3 className="text-white text-4xl mb-2">{ name }</h3>
        <p className="text-white mb-4">{ quote }</p>
        <div>
          <span className="text-white py-2 px-4 rounded-full bg-white bg-opacity-10 text-sm mb-2 inline-block">{ title }</span>
        </div>
      </div>
      
    </div>
  );
}

const Endorsements = ({ contentful, headline }) => {
  const { client } = contentful;
  const [activeEndorsementIndex, setActiveEndorsementIndex] = useState(0);
  const { data, error, fetched, loading } = useContentful({
    contentType: 'endorsementsOrder'
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No Endorsements :(.</p>;
  }

  const entries = client.parseEntries(data).items[0].fields.endorsements;
  const activeEndorsementItem = entries[activeEndorsementIndex];

  const nextEndorsement = () => {
    let nextIndex = activeEndorsementIndex + 1;
    if (nextIndex === entries.length) {
      nextIndex = 0;
    }
    setActiveEndorsementIndex(nextIndex);
  };
  const prevEndorsement = () => {
    let nextIndex = entries.length - 1;
    if (activeEndorsementIndex !== 0) {
      nextIndex = activeEndorsementIndex - 1;
    }
    setActiveEndorsementIndex(nextIndex);
  };

  return (
    <div className="flex flex-col py-8 md:py-16 px-8 items-center">
      <h3 className="text-white text-6xl mb-2"><ReactMarkdown>{ headline }</ReactMarkdown></h3>
      <p className="mb-8 text-white text-lg">Hear what some of our nation’s most influential individuals have to say about Charles</p>
      <div className="flex flex-row space-y-12 max-w-6xl items-center">
        <Button label="Prev" onClick={prevEndorsement} variant="circle" className="mr-2 lg:mr-16 bg-white flex-shrink-0" iconColor="#002B3D" Icon={NavArrowLeft} iconOnly />
        { activeEndorsementItem ? 
          <Endorsement name={activeEndorsementItem.fields.name} title={activeEndorsementItem.fields.title} quote={activeEndorsementItem.fields.quote} image={activeEndorsementItem.fields.photo.fields.file} key={activeEndorsementItem.sys.id} />
          :
          null
        }
        <Button label="Next" onClick={nextEndorsement} variant="circle" className="ml-2 lg:ml-16 bg-white flex-shrink-0" icon="prev" iconColor="#002B3D" Icon={NavArrowRight} iconOnly />
      </div>
    </div>
  );
};

export default withContentful(Endorsements);