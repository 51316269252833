import React from 'react';
import ReactDOM from 'react-dom/client';
import { ContentfulClient, ContentfulProvider } from 'react-contentful';
import { IconoirProvider } from 'iconoir-react';
import { Tooltip } from 'react-tooltip';
import Modal from 'react-modal';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import './index.css';
import App from './App';
import Home from './Home';
import SuccessStories from './SuccessStories';
import About from './About';
import Store from './Store';
import StartTransformation from './StartTransformation';
import Disclaimer from './Disclaimer';
import Pricing from './Pricing';
import reportWebVitals from './reportWebVitals';
import 'react-tooltip/dist/react-tooltip.css'
import FAQPage from './FAQPage';
import LifeCoaching from './LifeCoaching';
import PrivacyPolicy from './PrivacyPolicy';

const contentfulClient = new ContentfulClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  space: 'a322sv56wc6v',
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/transformation-program',
        element: <StartTransformation />,
      },
      {
        path: '/before-afters',
        element: <SuccessStories />,
      },
      {
        path: '/About',
        element: <About />,
      },
      {
        path: '/free-consultation',
        element: <StartTransformation />,
      },
      {
        path: '/store',
        element: <Store />,
      },
      {
        path: '/disclaimer',
        element: <Disclaimer />,
      },
      {
        path: '/pricing',
        element: <Navigate to="/free-consultation" replace={true} />,
      },
      {
        path: '/how-much-does-charles-dangelo-charge',
        element: <Navigate to="/free-consultation" replace={true} />,
      },
      {
        path: '/faqs',
        element: <FAQPage />
      },
      {
        path: '/life-coaching',
        element: <LifeCoaching />
      },
      {
        path: '/privacy',
        element: <PrivacyPolicy />
      },
    ]
  }
]);

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div id="app" className="App pt-16">
    <ContentfulProvider client={contentfulClient}>
      <IconoirProvider
        iconProps={{
          color: '#ffffff',
          strokeWidth: 2,
          width: '1em',
          height: '1em',
        }}
      >
        <RouterProvider router={router} />
      </IconoirProvider>
    </ContentfulProvider>
    <Tooltip id="tooltip" />
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
