import graph from './images/graph.svg';
import chain from './images/chain.svg';
import hat from './images/hat.svg';
import fingerprint from './images/fingerprint.svg';

const Benefit = ({ image = '', title = '', description = '' }) => {
  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="flex w-48 h-48 bg-lb rounded-full justify-center">
        <img src={image} />
      </div>
      <h4 className="text-white text-4xl">{title}</h4>
      <p className="text-white">{description}</p>
    </div>
  )
}

const ProgramBenefits = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-8 md:px-16 lg:px-32 py-12 md:py-16">
      <Benefit
        image={hat}
        title="Expert Guidance"
        description="You won't be navigating this journey alone. With the guidance of a seasoned weight loss coach who's been there himself, you'll have access to expert knowledge, proven strategies, and ongoing support."
      />
      <Benefit
        image={fingerprint}
        title="Personalized 1:1 Approach"
        description="You won't be navigating this journey alone. With the guidance of a seasoned weight loss coach who's been there himself, you'll have access to expert knowledge, proven strategies, and ongoing support."
      />
      <Benefit
        image={chain}
        title="Accountability"
        description="Through regular check-ins, goal-setting, and tailored guidance, he ensures consistent progress and empowers individuals to stay on track towards their weight loss goals.        "
      />
      <Benefit
        image={graph}
        title="Lasting Results"
        description="Experience a transformation that goes beyond quick fixes. Charles's program is designed to help you achieve sustainable weight loss, ensuring that the results you work hard for are here to stay."
      />
    </div>
  );
}

export default ProgramBenefits;