import { useContentful, withContentful } from 'react-contentful';
import ReactMarkdown from 'react-markdown';
import FAQs from './FAQs';
import Button from './Button';
import YoutubeEmbed from './YoutubeEmbed';

const PricingSection = ({ entry, index }) => {
  const { title, description, hideTitle, image, youtubeId, bgImage, showTransformationButton } = entry.fields;
  const sectionStyles = bgImage ? { backgroundImage: `url(${bgImage.fields.file.url})` } : {};
  const contentBg = (index % 2) ? 'bg-orange-100' : 'bg-blue-100';

  return (
    <section className="bg-cover bg-center w-full" style={sectionStyles}>
      <div className="mx-auto max-w-screen-2xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 text-left">
          { image ? (
            <div className="flex relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last aspect-w-1 aspect-h-1 self-center lg:-ml-12">
              <img
                  src={image.fields.file.url + '?fit=thumb&w=860&h=860&f=face&fit=fill'}
                  className="absolute inset-0 h-full w-full object-cover rounded-xl shadow-lg"
                />
            </div>
          ) : null }
          {youtubeId ? (
            <div className="flex relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last aspect-w-16 aspect-h-9 self-center lg:-ml-12">
              <YoutubeEmbed embedId={entry.fields.youtubeId} />
            </div>
          ) : null}
          <div className={ `p-12 lg:p-24 lg:pr-32 max-h-lg lg:-mr-12 ${contentBg} rounded-lg shadow-2xl` }>
            { !hideTitle && <h2 className="text-gray-800 text-3xl sm:text-4xl">{title}</h2> }
            <div className="my-4 text-gray-800 space-y-4">
              <ReactMarkdown>{description}</ReactMarkdown>
            </div>
            { showTransformationButton && <Button to="/free-consultation" label="Schedule My Consultation!" size="lg" /> }
          </div>
        </div>
      </div>
    </section>
  );
}



const Pricing = ({ contentful }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'pricingSectionOrder',
    include: 10
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No Pricing Sections :(</p>;
  }

  const entries = client.parseEntries(data).items[0].fields.order;

  return (
    <>
      <div className="flex flex-col mb-16">
        <div className="flex flex-col space-y-16 self-center w-full">
          { entries.map((entry, i) => 
            <PricingSection entry={entry} key={entry.sys.id} index={i} />
          )}
        </div>
      </div>
      <div className="pb-32">
        <FAQs />
      </div>
    </>
  );
}

export default withContentful(Pricing);