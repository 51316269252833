import { withContentful, useContentful } from 'react-contentful';
import ReactMarkdown from 'react-markdown';


const Disclaimer = ({ contentful }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'privacy',
    include: 10
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>Not found :(.</p>;
  }

  const entries = client.parseEntries(data);

  return (
    <div className="flex flex-col py-8 items-center">
      <h2 className="text-white text-6xl mb-8">{ entries.items[0].fields.title }</h2>
      <div className="max-w-4xl bg-white p-8 rounded-lg text-left">
        <ReactMarkdown className="markdown">{ entries.items[0].fields.content }</ReactMarkdown>
      </div>
    </div>
  )
};

export default withContentful(Disclaimer);