import './App.css';
import { withContentful, useContentful } from 'react-contentful';
import ConsultationForm from './ConsultationForm';
import ReactMarkdown from 'react-markdown';
import Publications from './Publications';
import Endorsements from './Endorsements';
import SuccessStoriesList from './SuccessStoriesList';
import MotivatedQuotes from './MotivatedQuotes';
import Doctors from './Doctors';
import VideoList from './VideoList';
import Button from './Button';
import about from './images/charles-before-after.jpg';
import { YouTube } from 'iconoir-react';

const convertArrayToObject = (arr, key) => {
  return arr.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item
    }),
    {}
  );
};

function Home({ contentful }) {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'sectionHeaders'
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No quotes :(.</p>;
  }

  const entries = client.parseEntries(data);
  const sectionHeaders = convertArrayToObject(entries.items.map(item => item.fields), 'slug');
  console.warn(sectionHeaders);

  return (
    <>
      <div className="hero relative flex justify-center px-8 -mt-16">
        <div className="relative flex flex-col md:flex-row py-16 md:py-32 text-left md:space-x-16 space-y-16 md:space-y-0 z-10 mt-16">
          <div className="max-w-lg">
            <h2 className="text-7xl uppercase text-white mb-2"><ReactMarkdown>{sectionHeaders.hero.headlineText}</ReactMarkdown></h2>
            <p className="text-white mb-2 text-lg">with Charles D'Angelo</p>
            <p className="text-white text-2xl leading-8 leading-relaxed">Have you tried <span className="text-lb font-bold">EVERYTHING</span> to lose weight and feel like nothing works? Finally discover what's been missing and begin your journey of <span className="text-orange-500 font-bold">REAL</span> change!</p>
          </div>
          <ConsultationForm />
        </div>
      </div>
      <Publications />
      <div className="flex flex-col md:flex-row gap-8 px-8 py-16 md:py-32 items-center justify-center">
        <div className="max-w-lg rounded-xl shadow-xl mt-4 corner-frame-bl md:w-1/2">
          <img className="rounded-xl" src={about} width="510" height="299" />
        </div>
        <div className="text-left max-w-lg md:w-1/2">
          <h3 className="text-white text-6xl mb-2 -rotate-2"><ReactMarkdown>{sectionHeaders.beenthere.headlineText}</ReactMarkdown></h3>
          <p className="text-white text-sm leading-6">Charles knows firsthand what it takes to turn your life around. Once overweight himself, Charles's personal experience fuels his unwavering dedication to helping others achieve their weight loss goals and live a more fulfilling life. With his relatable story, profound understanding, and unwavering support, Charles is the compassionate guide you need to embark on your own transformative journey towards a healthier, happier you.</p>
        </div>
      </div>
      <Endorsements headline={sectionHeaders.leaders.headlineText} />
      <MotivatedQuotes />
      <Doctors />
      <div className="flex flex-col px-8 py-16 md:py-32">
        <h3 className="text-white text-6xl mb-2"><ReactMarkdown>{sectionHeaders.beforeafter.headlineText}</ReactMarkdown></h3>
        <p className="text-white text-sm mb-8">Recent stories from real people that have transformed their lives with Charles</p>
        <SuccessStoriesList limit={4} />
        <div className="pt-12">
          <Button to="/before-afters" label="View More Before & Afters" size="lg" />
        </div>
      </div>
      <div className="py-16 md:py-32">
        <h3 className="text-white text-6xl mb-8">Latest Videos</h3>
        <div className="mb-16">
          <VideoList />
        </div>
        <Button to="https://www.youtube.com/@charlesdangelo314/videos" label="Watch 690+ Videos" size="lg" target="_blank" Icon={YouTube} />
      </div>
    </>
  );
}

export default withContentful(Home);
