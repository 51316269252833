import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Button from './Button';
import YoutubeEmbed from './YoutubeEmbed';
import { Cancel } from 'iconoir-react';

const ytUrl = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=4&playlistId=UUvg-7q3_HMZ9suANL0Ox4nQ&key=AIzaSyCtgouhQ_PNBSkAYxf1yWs0zzveRv7FTLs';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible'
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0,0,0,0.6)',
    textAlign: 'center'
  }
};

const Video = ({ video }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  let img = '';
  if (video.snippet.thumbnails.maxres) {
    img = video.snippet.thumbnails.maxres.url;
  } else if (video.snippet.thumbnails.high) {
    img = video.snippet.thumbnails.high.url;
  } else if (video.snippet.thumbnails.high) {
    img = video.snippet.thumbnails.high.url;
  }
  return (
    <>
      <div onClick={() => setIsOpen(true)} className="flex flex-col cursor-pointer" target="_blank">
        <div className="aspect-w-16 aspect-h-9 mb-2 rounded">
          <img src={img} />
        </div>
        <span className="text-white text-left">{video.snippet.title}</span>
      </div>
      <Modal style={customStyles} isOpen={modalIsOpen}>
        <div className="absolute -top-6 -right-6">
          <Button className="w-full bg-white" onClick={() => setIsOpen(false)} variant="circle" Icon={Cancel} iconColor="#000000" iconSize="2em" iconOnly />
        </div>
        <div className="w-[320px] sm:w-[640px]">
          <YoutubeEmbed embedId={video.contentDetails.videoId} />
        </div>
      </Modal>
    </>
  );
};

const VideoList = () => {
  const [videos, seVideos] = useState([]);
  
  useEffect(() => {
    fetch(ytUrl)
    .then(response => response.json())
    .then(data => {
      seVideos(data.items);
    });
  }, []);

  if (!videos.length) {
    return <p>Loading...</p>
  }
  
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 px-12 md:px-32 lg:px-32 xl:px-48">
        { videos.map((video, i) => (
          <Video video={video} key={i} />
        )) }
      </div>
    </div>
    
  );
};

export default VideoList;