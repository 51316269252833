import { useState, useEffect } from 'react';
import Button from './Button';
import { Cart } from 'iconoir-react';

const Product = ({title = '', description = '', image = '', variants = [], handle = ''}) => {
  const availableVariants = variants.filter(variant => variant.available);
  let price;
  if (availableVariants < 1) {
    price = <p className="text-white rounded-full bg-white bg-opacity-20 px-4 py-1">Sold Out</p>;
  } else if (availableVariants.length === 1) {
    price = <p className="text-white rounded-full bg-white bg-opacity-20 px-4 py-1">${variants[0].price}</p>;
  } else {
    price = (
      <>
        {availableVariants.map((variant, i) => 
          <p className="text-white rounded-full bg-white bg-opacity-20 px-4 py-1" key={i}>{variant.option1}: ${variant.price}</p>
        )}
      </>
    );
  }
  const url = 'https://store.charlesdangelo.com/collections/frontpage/products/' + handle;
  return (
    <div className="flex flex-row text-left px-4 gap-6">
      <a href={url} target="_blank" className="text-white text-3xl min-w-[150px] w-[300px] md:flex-shrink-0"><img src={image} width="300" /></a>
      <div className="flex flex-col gap-4 md:pt-4">
        <a href={url} target="_blank" className="text-white text-xl md:text-3xl">{title}</a>
        <div className="flex flex-row gap-2">
          {price}
        </div>
        <div className="text-white" dangerouslySetInnerHTML={{__html: description}} />
        <div>
          <Button className="flex-grow-0 w-auto" label="Go to Product" Icon={Cart} to={url} target="_blank" />
        </div>
      </div>
    </div>
  );
};

const Store = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('https://store.charlesdangelo.com/products.json')
      .then(res => res.json())
      .then(data => {
        setProducts(data.products);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (products.length > 0) {
    console.warn(products);
  }

  return (
    <div className="flex flex-col items-center pt-12">
      <h2 className="text-white text-6xl mb-2">Your One-stop<br /><span className="text-lb">Transformation</span> Shop</h2>
      <p className="text-white mb-16">Unleash your full potential with Charles's weight loss products.</p>
      <div className="flex flex-col gap-8 max-w-4xl pb-16">
        {products.map((product, i) =>
          <Product
            title={product.title}
            description={product.body_html || ''}
            variants={product.variants}
            handle={product.handle}
            image={product.images[0].src}
            key={i}
          />
        )}
      </div>
    </div>
  );
}

export default Store;