import { useContentful, withContentful } from 'react-contentful';
import { YouTube, Facebook, Instagram } from 'iconoir-react';
import { Link } from 'react-router-dom';

const Footer = ({ contentful }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'navigationOrder',
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No navigation :(</p>;
  }

  const navItems = client.parseEntries(data).items[0].fields.order;
  
  return (
    <footer className="bg-cb">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Link href="/" className="flex">
              <h1 className="text-2xl text-white logo">Charles D'Angelo</h1>
            </Link>
          </div>
          <ul className="grid grid-cols-3 text-gray-400 font-medium text-left gap-4">
            {navItems.map(navItem => (
              <li key={navItem.sys.id}>
                <Link className="text-white hover:text-orange-400 transition flex-grow p-2" to={navItem.fields.link}>{navItem.fields.label}</Link>
              </li>
             ))}
          </ul>
        </div>
        <hr className="my-6 border-gray-700 sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-400 sm:text-center">© 2023 <a href="/" className="hover:underline">Charles D'Angelo</a>
          </span>
          <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
            <a href="https://www.youtube.com/user/charlesdangelo314" className="text-gray-500 hover:text-gray-900" target="_blank">
              <YouTube width="1.5rem" height="1.5rem" />
              <span className="sr-only">Facebook page</span>
            </a>
            <a href="https://www.facebook.com/teamcharlesstl/" className="text-gray-500 hover:text-gray-900" target="_blank">
              <Facebook width="1.5rem" height="1.5rem" />
              <span className="sr-only">Facebook community</span>
            </a>
            <a href="https://www.instagram.com/charlesdangelo314" className="text-gray-500 hover:text-gray-900" target="_blank">
              <Instagram width="1.5rem" height="1.5rem" />
              <span className="sr-only">Instagram page</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withContentful(Footer);