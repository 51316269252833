import { withContentful, useContentful } from "react-contentful";
import YoutubeEmbed from "./YoutubeEmbed";

const successEntries = [
  '3d6m8CumrwKnIGNGObbnWy',
  '7BZTFsjDH696RFzKAvYDf4',
  'GHh5OYuEmBn43CY7wRAo1',
  '4sOnphG4vG3CJRVeXAGeB7',
  'SWbFy3R4BZ8Mj4TTpS89C',
  '6q8PIjUhenYx8brIaLo2O6'
];

const successVideos = [
  'aAzzgRJuM0A',
  'hYw62GYb0Og',
  'mcojFx_YptY'
];

const FancyStoryItem = ({ entry }) => {
  const { name, description, weightLost, image } = entry.fields;
  return (
    <div className="flex items-center space-x-4 text-left p-2 rounded-xl">
      <div className="flex-shrink-0">
        <img className="w-48 h-48 rounded-xl" src={image.fields.file.url + '?fit=thumb&w=480&h=480&f=center'} />
      </div>
      <div className="flex-1 min-w-0 w-96">
        <p className="text-lg font-semibold text-white truncate">
          { name }
        </p>
        <p className=" text-white truncate mb-2">
          Lost <span className="text-orange-500 font-semibold">{ weightLost }lbs</span>
        </p>
        <p className="text-xs text-white max-w-xl">
          { description }
        </p>
      </div>
    </div>
  );
}

const FancySuccessStories = ({ contentful }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'successStories',
    include: 10,
    query: {
      'fields.consultationFeature': 'true'
    }
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No Before & Afters :(.</p>;
  }

  const entries = client.parseEntries(data);
  const list1 = [
    entries.items[5],
    entries.items[4]
  ];
  const list2 = [
    entries.items[3],
    entries.items[2]
  ];
  const list3 = [
    entries.items[1],
    entries.items[0]
  ];

  return (
    <div className="flex flex-col mb-32 space-y-4 px-12 md:px-16 lg:px-24 xl:px-32">
      <div className="flex flex-row space-x-4 justify-center">
        <div className="flex flex-col space-y-4 self-center">
          {list1.map(entry => (
            <FancyStoryItem entry={entry} />
          ))}
        </div>
        <div className="w-full max-w-4xl">
          <div className="p-4 rounded-lg bg-orange-100">
            <YoutubeEmbed embedId={successVideos[0]} />
          </div>
        </div>
      </div>
      <div className="flex flex-row space-x-4 justify-center">
        <div className="w-full max-w-4xl">
          <div className="w-full p-4 rounded-lg bg-orange-100">
            <YoutubeEmbed embedId={successVideos[1]} />
          </div>
        </div>
        <div className="flex flex-col space-y-4 self-center">
          {list2.map(entry => (
            <FancyStoryItem entry={entry} />
          ))}
        </div>
      </div>
      <div className="flex flex-row space-x-4 justify-center">
        <div className="flex flex-col space-y-4 self-center">
          {list3.map(entry => ( 
            <FancyStoryItem entry={entry} />
          ))}
        </div>
        <div className="w-full max-w-4xl">
          <div className="p-4 rounded-lg bg-orange-100">
            <YoutubeEmbed embedId={successVideos[2]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withContentful(FancySuccessStories);