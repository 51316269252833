import { useState } from 'react';
import { useContentful, withContentful } from 'react-contentful';
import Modal from 'react-modal';
import Button from './Button';
import YoutubeEmbed from './YoutubeEmbed';
import { Play, Cancel } from 'iconoir-react';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible'
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0,0,0,0.6)',
    textAlign: 'center'
  }
};

const SuccessStory = ({ entry = {} }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <div className="flex flex-col bg-white p-3 pb-1 rounded shadow-lg" key={entry.sys.id}>
      <div className="relative mb-1">
        <div>
          <img src={entry.fields.image.fields.file.url + '?fit=thumb&w=530&h=438&f=center'} loading="lazy" />
        </div>
        {entry.fields.youtubeID &&
          <div className="absolute bottom-2 right-2">
            <Button className="w-full bg-white" onClick={() => setIsOpen(true)} variant="circle" Icon={Play} iconColor="#FF8C00" iconSize="2em" iconOnly />
          </div>
        }
      </div>
      <div className="flex flex-row justify-between leading-8 py-1">
        <span className="overflow-hidden md:text-lg lg:text-lg whitespace-nowrap text-ellipsis">{entry.fields.name}</span>
        <span className="flex-shrink-0 md:text-lg lg:text-lg">Lost <span className="text-orange-500 font-semibold">{entry.fields.weightLost}lbs</span></span>
      </div>
      {entry.fields.youtubeID &&
        <Modal style={customStyles} isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)}>
          <div className="absolute -top-6 -right-6">
            <Button className="w-full bg-white" onClick={() => setIsOpen(false)} variant="circle" Icon={Cancel} iconColor="#000000" iconSize="2em" iconOnly />
          </div>
          <div className="w-[320px] sm:w-[640px]">
            <YoutubeEmbed embedId={entry.fields.youtubeID} />
          </div>
        </Modal>
      }
    </div>
  );
};

const SuccessStoriesList = ({ contentful, limit = 1000 }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'successStories',
    include: 10,
    query: {
      limit
    }
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No Before & Afters :(.</p>;
  }

  const entries = client.parseEntries(data);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 px-12 md:px-32 lg:px-32 xl:px-48">
      { entries.items.map((entry, i) => <SuccessStory entry={entry} key={i} />) }
    </div>
  );
};

export default withContentful(SuccessStoriesList);