import { useEffect, useState, useRef } from 'react';
import { useContentful, withContentful } from 'react-contentful';
import { Plus } from 'iconoir-react';

const FAQItem = ({ question = '', answer = '', open = false, onItemClick = () => {} }) => {
  const answerRef = useRef();
  const [answerHeight, seAnswerHeight] = useState(0);

  useEffect(() => {
    if (!answerRef?.current?.clientHeight) {
      return;
    }
    seAnswerHeight(answerRef?.current?.clientHeight);
  }, [answerRef?.current?.clientHeight]);

  return (
    <div className={ 'bg-white rounded hover:bg-opacity-10 transition duration-300 ' + (open ? 'bg-opacity-10' : 'bg-opacity-5') }>
      <div>
        <button type="button" onClick={ onItemClick } className="flex items-center space-x-4 w-full p-5 font-semibold text-left text-white text-sm text-left">
          <Plus className={ 'transition ' + (open ? 'rotate-45' : '') } width="2em" height="2em" strokeWidth="2" />
          <span>{ question }</span>
        </button>
      </div>
      <div className="transition-all overflow-hidden" style={{ height: open ? (answerHeight + 'px') : '0px' }}>
        <div className="p-5" ref={answerRef}>
          <p className="mb-2 text-white text-sm text-left">{ answer }</p>
        </div>
      </div>
    </div>
  );
};

const FAQs = ({ contentful }) => {
  const [activeAccordion, setActiveAccordion] = useState(-1);
  const { client } = contentful;

  const { data, error, fetched, loading } = useContentful({
    contentType: 'faq',
    include: 10
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No FAQs :(</p>;
  }

  const entries = client.parseEntries(data);
  const onItemClick = i => {
    if (i ===activeAccordion ) {
      setActiveAccordion(-1);
      return
    }
    setActiveAccordion(i);
  };

  return (
    <div className="flex flex-col justify-center py-16 px-4">
      <div className="flex flex-col max-w-2xl self-center">
        <h2 className="text-white text-6xl mb-4">Frequently Asked Questions</h2>
        <div className="flex flex-col space-y-1">
          { entries.items.map((entry, i) => (
            <FAQItem question={entry.fields.question} answer={entry.fields.answer} open={activeAccordion === i} key={i} onItemClick={() => {onItemClick(i)}} />
          )) }
        </div>
      </div>
    </div>
  );
};

export default withContentful(FAQs);