import { useNavigate } from "react-router-dom";

const Button = ({
  label = 'Submit',
  className = '',
  type = 'button',
  to = '',
  onClick = null,
  size = '',
  loading = false,
  loadingLabel= '',
  target = '',
  variant = '',
  Icon = null,
  iconSize = '2em',
  iconColor = '#ffffff',
  iconOnly = false
}) => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    if (onClick) {
      onClick();
    }
    if (target === '_blank') {
      window.open(to);
      return;
    }
    if (to) {
      navigate(to);
    }
  };

  let btnPadding = 'px-6 py-3';
  let textSize = 'text-xs';
  let btnWidth = '';
  let btnHeight = '';
  let btnRounded = 'rounded-sm';
  
  if (size === 'lg') {
    btnPadding = 'px-10 py-4';
    textSize = '';
  }

  if (variant === 'circle') {
    btnRounded = 'rounded-full';
    btnPadding = 'p-3'
  }

  let btnLabel = label;
  if (loading && loadingLabel) {
    btnLabel = loadingLabel;
  }

  return (
    <button onClick={onClick || onButtonClick} className={ `text-white bg-orange-500 hover:bg-orange-500 ${btnWidth} ${btnHeight} ${btnPadding} ${btnRounded} transition ${textSize} font-semibold ${className}` } type={type}>
      { Icon ? <Icon className={`inline-block ${!iconOnly ? 'mr-2' : ''}`} color={iconColor} width={iconSize} height={iconSize} /> : null }
      { !iconOnly ? <span>{btnLabel}</span> : null }
    </button>
  );
}

export default Button;