import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContentful, withContentful } from 'react-contentful';
import { Menu, Cancel } from 'iconoir-react';
import Button from './Button';

const Header = ({ contentful }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'navigationOrder',
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const onConsultationClick = () => {
    if (location.pathname === '/free-consultation') {
      window.scrollTo(0, 0);
      return;
    }
    navigate('/free-consultation');
  };

  const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No navigation :(</p>;
  }

  const navItems = client.parseEntries(data).items[0].fields.order;
  const bg = scrollPosition > 100 ? 'bg-cb bg-opacity-90' : '';

  return (
    <header className={ `fixed w-full flex items-center h-16 px-2 sm:px-4 md:px-8 top-0 justify-between z-20 transition duration-300 ${bg}` }>
      <div className="flex flex-row gap-2 items-center">
        <Menu className="cursor-pointer sm:hidden" width="2em" height="2em" color="#ffffff" onClick={() => setMenuOpen(!menuOpen)} />
        <a href="/" className="flex flex-shrink-0">
          <h1 className="text-2xl text-white logo">Charles D'Angelo</h1>
        </a>
      </div>
      <nav className="flex content-center hidden lg:block">
        <ul className="flex items-center space-x-2">
          {navItems.map(navItem => (
            <li key={navItem.sys.id}>
              <Link className="text-white hover:text-orange-400 transition flex-grow p-2" to={navItem.fields.link}>{navItem.fields.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
      <Button className="px-4 sm:px-8" onClick={onConsultationClick} label="Free Consultation" />
      { menuOpen ?
        <div className="bg-cb fixed top-0 left-0 right-0 bottom-0">
          <Cancel className="cursor-pointer absolute top-[10px] right-[10px]" onClick={() => setMenuOpen(false)} width="2em" height="2em" color="#ffffff" />
          <ul className="flex flex-col space-x-2 pt-16">
            {navItems.map(navItem => (
              <li key={navItem.sys.id}>
                <Link className="block text-white hover:text-orange-400 transition flex-grow px-2 py-4" to={navItem.fields.link} onClick={() => setMenuOpen(false)}>{navItem.fields.label}</Link>
              </li>
            ))}
          </ul>
        </div> : null
      }
    </header>
  );
};

export default withContentful(Header);