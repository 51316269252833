import { withContentful, useContentful } from 'react-contentful';
import { QuoteMessage } from 'iconoir-react';
import Button from './Button';

const Quote = ({ quote = '' }) => {
  return (
    <div className="relative w-96 text-left flex-grow-0 corner-frame-bl">
      <div className="bg-white rounded-xl p-6">
        <span className="text-gray-900">{quote}</span>
        <div className="absolute rounded-full bg-white p-3 -top-6 -right-6">
          <QuoteMessage width="2em" height="2em" color="#FF8C00" />
        </div>
      </div>
    </div>
  );
};

const MotivatedQuotes = ({ contentful }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'quotes',
    include: 10
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No quotes :(.</p>;
  }

  const entries = client.parseEntries(data);

  return (
    <div className="flex flex-col py-16 md:py-32 justify-center items-center px-12">
      <h3 className="text-white text-6xl">Does this <span className="text-lb">sound like you?</span></h3>
      <p className="mb-16 text-white text-lg">If you've thought just one of these, Charles can help!</p>
      <div className="flex flex-wrap justify-center gap-12 max-w-6xl mb-32">
        {entries.items.map((quote, i) => 
          <Quote quote={quote.fields.quote} key={i} />
        )}
      </div>
      <h3 className="text-white text-6xl mb-8">We won't just <span className="text-lb">fix</span> the problem...<br />we'll address what's <span className="text-orange-500">caused</span> it!</h3>
      <Button to="/free-consultation" label="Schedule My Consultation!" size="lg" />
    </div>
  );
};

export default withContentful(MotivatedQuotes);