import { useState } from 'react';
import { withContentful, useContentful } from 'react-contentful';
import Modal from 'react-modal';
import Button from './Button';
import YoutubeEmbed from './YoutubeEmbed';
import { Play, YouTube, Cancel } from 'iconoir-react';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible'
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0,0,0,0.6)',
    textAlign: 'center'
  }
};

const Doctor = ({ quote = '', name = '', image = '', youtubeId = '', title = '' }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="flex flex-col items-center">
        <div className="relative text-center flex-grow-0 corner-frame-tl">
          <div className="bg-white rounded-xl p-6">
            <p className="text-gray-900 mb-4">{ quote }</p>
            <p className="text-gray-900 font-semibold">{ name }</p>
            <p className="text-gray-900 text-xs">{ title }</p>
          </div>
        </div>
        <div className="arrow-down mb-2"></div>
        <div className="relative">
          <img className="rounded-full shadow" src={image} width="140" height="140" />
          <div className="absolute -bottom-3 -right-3">
            { youtubeId ? <Button className="w-full bg-white" onClick={() => setIsOpen(true)} to={`https://youtube.com/watch?=${youtubeId}`} variant="circle" Icon={Play} iconColor="#FF8C00" iconSize="2em" iconOnly /> : null }
          </div>
        </div>
      </div>
      <Modal style={customStyles} isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)}>
        <div className="absolute -top-6 -right-6">
          <Button className="w-full bg-white" onClick={() => setIsOpen(false)} variant="circle" Icon={Cancel} iconColor="#000000" iconSize="2em" iconOnly />
        </div>
        <div className="w-[320px] sm:w-[640px]">
          <YoutubeEmbed embedId={youtubeId} />
        </div>
      </Modal>
    </>
  );
};

const Doctors = ({ limit = 3, contentful }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'doctors',
    include: 10,
    query: {
      limit
    }
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No Before & Afters :(.</p>;
  }

  const entries = client.parseEntries(data);

  return (
    <div className="flex flex-col py-16 md:py-32 content-end items-center px-8">
      <h3 className="text-white text-6xl">Who <span className="text-lb">doctors</span> call for help!</h3>
      <p className="mb-16 text-white text-lg">Charles has helped countless doctors change their lives for the better</p>
      <div className="flex grid grid-cols-1 md:grid-cols-3 justify-center gap-12 max-w-6xl mb-12">
        { entries.items.map((item, i) =>
          <Doctor
            quote={item.fields.quote}
            name={item.fields.name}
            image={item.fields.image.fields.file.url}
            youtubeId={item.fields.youtubeId}
            title={item.fields.title}
            key={i}
          />
        )}
      </div>
      <Button size="lg" label="More Doctor Success Videos!" to="https://www.youtube.com/playlist?list=PLGO9T1zD4PpECW3I6cGnmCXYthFHw3NIA" target="_blank" Icon={YouTube} />
    </div>
  );
};

export default withContentful(Doctors);