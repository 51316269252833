import { useContentful, withContentful } from 'react-contentful';

const Publications = ({ contentful }) => {
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'publication',
    include: 10
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No publications :(.</p>;
  }

  const entries = client.parseEntries(data);

  return (
    <div className="flex flex-row flex-wrap items-center justify-center gap-8 p-4 -mb-8">
      {entries.items.map(item => {
        const cursor = item.fields.url ? 'cursor-pointer' : '';
        const onClick = () => {
          if (item.fields.url) {
            window.open(item.fields.url);
          }
        }
        return (
          <div className={`mb-8 ${cursor}`} key={item.sys.id} onClick={onClick}>
            <img src={item.fields.logo.fields.file.url} height={item.fields.height || 0} width={item.fields.width || 0} />
          </div>
        );
      }
        
      )}
    </div>
  );
}

export default withContentful(Publications);