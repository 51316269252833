import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from "react-ga4";
import Header from './Header';
import Footer from './Footer';

ReactGA.initialize('G-V4H7GXJMQS');

const App = ({children}) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Header />
        <Outlet />
      <Footer />
      <ToastContainer
        position="bottom-left"
        theme="dark"
      />
    </>
  );
}

export default App;