import { useEffect, useState } from 'react';
import { useContentful, withContentful } from 'react-contentful';
import { toast } from 'react-toastify';
import SuccessStoriesList from './SuccessStoriesList';
import Button from './Button';
import Modal from 'react-modal';
import YoutubeEmbed from './YoutubeEmbed';
import { Play, Cancel } from 'iconoir-react';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible'
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0,0,0,0.6)',
    textAlign: 'center'
  }
};

const CustomToast = () => {
  return (
    <div>
      <p>These representations are those of our coaching clients and are not the representations of Team Charles LLC. <a className="text-lb" href="/disclaimer">Disclaimer</a></p>
    </div>
  );
}

const SuccessStories = ({ contentful }) => {
  const [activeStory, setActiveStory] = useState('');
  const [activeRotateClass, setActiveRotateClass] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { client } = contentful;
  const { data, error, fetched, loading } = useContentful({
    contentType: 'successStories',
    include: 10,
    query: {
      'fields.featured': true,
    }
  });

  const notify = () => toast.info(CustomToast, {
    autoClose: false,
    toastId: 'success'
  });
  
  useEffect(() => {
    notify();

    return () => {
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    setActiveRotateClass('rotate-' + Math.floor( Math.random() * 7 ));
  }, [activeStory]);

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data.items.length < 0) {
    return <p>No Before & Afters :(.</p>;
  }

  if (!activeStory) {
    setActiveStory(data.items[0].sys.id);
  }

  const entries = client.parseEntries(data);
  const activeStoryItem = entries.items.find(item => item.sys.id === activeStory);

  return (
    <>
      <div className="flex justify-center py-8">
        <div className="max-w-lg">
          <h1 className="text-white text-6xl mb-2">Before & Afters</h1>
          <p className="text-white text-sm">Charles has helped hundreds of people achieve their weight loss goals. Explore below to see the proof that he can help you too.</p>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex flex-col md:flex-row rounded-lg md:rounded-full bg-white p-1 space-x-1">
          {entries.items.map(item => { 
            const active = activeStory === item.sys.id;
            const textColor = active ? 'text-white' : 'text-black'
            return (
              <div className={ 'flex flex-col text-left py-4 cursor-pointer transition duration-200 rounded-md md:rounded-full text-center px-12 basis-lg ' + (active && ' bg-orange-500' || 'hover:bg-gray-100') } onClick={() => setActiveStory(item.sys.id)} key={item.sys.id}>
                <span className={ 'transition font-semibold ' + textColor }>{item.fields.name}</span>
                <span className={ 'transition ' + textColor }>Lost {item.fields.weightLost}lbs</span>
              </div>
            );
          })}
        </div>
      </div>
      { activeStoryItem &&
        <div className="flex flex-col md:flex-row-reverse justify-center items-center py-16 gap-8">
          <div>
            <img className={ 'border-8 border-white rounded-2xl transition duration-300 ' + activeRotateClass } src={activeStoryItem.fields.image.fields.file.url} width="400" height="244" />
          </div>
          <div className="text-left max-w-md">
            <h2 className="text-white text-3xl mb-2">{ activeStoryItem.fields.name }</h2>
            <p className="text-white text-sm leading-relaxed mb-4">{ activeStoryItem.fields.description }</p>
            <div className="flex flex-row space-x-4">
              <Button to="/free-consultation" label="Schedule My Consultation!" />
              {activeStoryItem.fields.youtubeID &&
                <Button onClick={() => setModalIsOpen(true)} label="Watch the Video" target="_blank" Icon={Play} />
              }
            </div>
          </div>
          {activeStoryItem.fields.youtubeID &&
            <Modal style={customStyles} isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
              <div className="absolute -top-6 -right-6">
                <Button className="w-full bg-white" onClick={() => setModalIsOpen(false)} variant="circle" Icon={Cancel} iconColor="#000000" iconSize="2em" iconOnly />
              </div>
              <div className="w-[320px] sm:w-[640px]">
                <YoutubeEmbed embedId={activeStoryItem.fields.youtubeID} />
              </div>
            </Modal>
          }
        </div>
      }
      <div className="flex flex-col">
        <h2 className="text-white text-6xl">15 Years of <span className="text-orange-400">Success</span></h2>
        <p className="text-white mb-8">Explore the full history of people that have discovered a new life through Charles’ weight loss program.</p>
        <div className="flex flex-row space-x-4 justify-center hidden">
          <Button label="Age" />
          <Button label="Weight Lost" />
          <Button label="Gender" />
        </div>
      </div>
      <div className="pb-16">
        <SuccessStoriesList />
      </div>
      <div className="pb-16">
        <h2 className="text-white text-6xl">Ready to be a <span className="text-lb">success?</span></h2>
        <p className="text-white mb-4">Start your transformation today!</p>
        <Button label="Schedule my consultation!" size="lg" to="/free-consultation" />
      </div>
    </>
  );
}

export default withContentful(SuccessStories);